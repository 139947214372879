<template>
    <div>
        <!-- Page Title Section -->
        <section class="page-title">
            <div class="pattern-layer-one" :style="{backgroundImage: 'url('+bgPattern5+')'}"></div>
            <div class="pattern-layer-two" :style="{backgroundImage: 'url('+bgPattern6+')'}"></div>
            <div class="icon-layer-one" :style="{backgroundImage: 'url('+icon5+')'}"></div>
            <div class="icon-layer-two" :style="{backgroundImage: 'url('+icon5+')'}"></div>
            <div class="icon-layer-three" :style="{backgroundImage: 'url('+icon6+')'}"></div>
            <div class="icon-layer-four" :style="{backgroundImage: 'url('+iconPattern1+')'}"></div>
            <div class="icon-layer-five" :style="{backgroundImage: 'url('+iconPattern2+')'}"></div>
            <div class="auto-container">
                <h1>Legal</h1>
                <ul class="page-breadcrumb">
                    <li>
                        <router-link :to="{name: 'Home'}">home</router-link>
                    </li>
                    <li>Legal Information</li>
                </ul>
            </div>
        </section>
        <!-- End Page Title Section -->

        <!-- Privacy Section -->
        <section class="privacy-section" style="margin-bottom: 170px">
            <div class="auto-container">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <!-- Privacy Content -->
                        <div class="privacy-content">
                            <h2>Legal Information</h2>
                            <p>
                                TrustEarns is one of the most reliable diversified financial investment service provider in the world. Our fundamental mission is to help customers and clients achieve their financial objectives. With offices locate in the United States, Australia,Canada and headquartered in Uk, TrustEarns have staffs with over 30 years’ experience in combining financial development and trades. We are committed to pushing the boundaries of what constitutes the highest standards in trades and asset management.
                            </p>
                            <p>
                                With over $5.3 Trillion traded daily in the market, we are dedicated to giving our clients their own share of the profit daily. As a leading global market maker, TrustEarns has one of the World’s Largest Independent traders and fund managers with clients from different parts of the World. Today, as a successful trade investment and asset management company, we are trusted by thousands of clients. Our sterling reputation, dedication to meeting our clients’ needs and innovative approach to business development are some driving forces behind our success.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <img src="../../assets/images/TE_cert.png" alt="TrustEarn Legal certificate" class="img-fluid">
                    </div>
                </div>
            </div>
        </section>
        <!-- End Privacy Section -->

    </div>
</template>

<script>
import icon1 from "../../assets/images/icons/icon-1.png";
import icon2 from "../../assets/images/icons/icon-2.png";
import icon3 from "../../assets/images/icons/icon-3.png";
import icon4 from "../../assets/images/icons/icon-4.png";
import bgPattern5 from "../../assets/images/background/pattern-5.png";
import bgPattern6 from "../../assets/images/background/pattern-6.png";
import icon5 from "../../assets/images/icons/icon-5.png";
import icon6 from "../../assets/images/icons/icon-6.png";
import iconPattern1 from "../../assets/images/icons/pattern-1.png";
import iconPattern2 from "../../assets/images/icons/pattern-2.png";

export default {
    name: "Privacy",
    data(){
        return {
            icon1, icon2, icon3, icon4,
            bgPattern5, bgPattern6,
            icon5, icon6, iconPattern1,
            iconPattern2
        }
    }
}
</script>

<style scoped>

</style>